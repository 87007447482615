<template>

    <li class="appointments info-list" @click="toggleAppointments" v-if="appointmentOfTheDay!=null || nextAppointment!=null">
        <div class="info-list__icon">
            <span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">schedule</span>
        </div>
        <div class="info-list__content">
            <h6 class="text-black appointment-type" v-if="appointmentType!=null">
                {{appointmentType}}
            </h6>
            <div class="appointment-type" v-if="false && hasInterval">
                <span>{{interval}}</span><br/>
                <span>У період від {{intervalStart}} - {{intervalEnd}}</span><br/>
            </div>

            <h6 class="text-black today" v-if="appointmentOfTheDay!=null">
                <span>Сьогодні</span>
                <span>
                    <div class="time" v-for="(time,index) in appointmentOfTheDay.times" :key="index">{{formatTime(time.time_from)}} - {{formatTime(time.time_to)}} годинник</div>
                </span>
            </h6>
            <h6 class="text-black" v-else-if="nextAppointment!=null && nextAppointment.date==null && nextAppointment.wday!=null">
                <span>{{ getWeekday(nextAppointment.wday).substring(0,2) }}, </span>
                <span>{{formatTime(nextAppointment.time.time_from)}} - {{formatTime(nextAppointment.time.time_to)}} годинник</span>
            </h6>
            <h6 class="text-black" v-else-if="nextAppointment!=null && nextAppointment.date!=null">                
                <span>{{ formatDate(nextAppointment.date) }}, </span>
                <span>{{ formatTime(nextAppointment.time.time_from)}} - {{formatTime(nextAppointment.time.time_to)}} годинник</span>
            </h6>

            <button class="btn btn-link" style="color:#3A3A3A" aria-label="Öffnungszeiten an anderen Tagen anzeigen" v-if="appointments!=null && appointments.days.length>0">Показати більше дат</button>
            <display-appointments v-if="isActiveTime" class="display-appointments" :appointments="appointments"></display-appointments>
        </div>
        <div class="info-list__more"><span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">{{isActiveTime ? 'expand_less' : 'expand_more'}}</span></div>
    </li>

</template>

<script>

    import DisplayAppointments from './appointmentsDropdownContents.vue';
    import { getNextAppointment, isAppointmentNow, formatDate, formatTime, getWeekday, isOneOfTheAppointmentsToday, getMonth } from '@/utils/appointment-helpers';

    export default {
        name: "appointmentsDropdown",
        components: {
            DisplayAppointments,
        },
        props: {
            appointments: {
                type: Object,
                default: null,
            },
            appointmentType: {
                type: String,
                default: null,
            },
            additionalStyles: {
                type: String,
                default: "",
            }
        },
        data() {
            return {
                isActiveTime: false
            }
        },
        computed: {
           nextAppointment(){
            return getNextAppointment(this.appointments);
        },
        nextAppointmentWday(){
            if(this.nextAppointment!=null && this.nextAppointment.wday!=null){
                var day = getWeekday(this.nextAppointment.wday);
                return day.substring(0,2);
            }
            return null;
        },
        nextAppointmentDate(){
            if(this.nextAppointment!=null && this.nextAppointment.date!=null){
                var date = new Date(this.nextAppointment.date);
                return date.getDate();
            }
            return null;
        },
        hasInterval(){
            if(this.appointments.hasOwnProperty('interval_end') && this.appointments.hasOwnProperty('interval_start')){
                return true;
            }
            return false;
        },
        intervalStart(){
            if(this.hasInterval){
                return formatDate(this.appointments.interval_start);
            }
            return null;
        },
        intervalEnd(){
            if(this.hasInterval){
                return formatDate(this.appointments.interval_end);
            }
            return null;
        },
        interval(){
            if(this.hasInterval){
                if(this.appointments.interval === 1){
                    return "Findet wöchentlich statt";
                }
                else if(this.appointments.interval>1){
                    return `Findet alle ${this.appointments.interval} Wochen statt`;
                }
            }
            return null;
        },
        hasExceptions(){
            if(this.appointments.exceptions.length>0){
                return true;
            }
            return false;
        },

        nextAppointmentStartTime(){
            if(this.nextAppointment!=null && this.nextAppointment.time!=null && this.nextAppointment.time.time_from!=null){
                var time = this.nextAppointment.time.time_from;
                return formatTime(time);
            }
            return null;
        },
        nextAppointmentMonthAndYear(){
            if(this.nextAppointment!=null && this.nextAppointment.date!=null){
                var date = new Date(this.nextAppointment.date);
                var month = getMonth(date.getMonth()+1);
                var year = date.getFullYear();
                return month.substring(0,3) + '. '+year;
            }
            return null;
        },
        appointmentOfTheDay(){           
         if(this.appointments!=null){
             return isOneOfTheAppointmentsToday(this.appointments);
         }
         return null;
     },
 },   
 methods: {
    formatTime,
    isAppointmentNow,
    formatDate,
    getWeekday,
    toggleAppointments(){
        this.isActiveTime = !this.isActiveTime;
        /*hide map if activeTime is shown*/
        if(this.isActiveTime){
            this.isActiveLocation = false;
        }
    },
}
}
</script>

<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';

    .appointments{

        .appointment-type{
            margin-bottom: 5px;
        }

        .today{
            span{
                display: inline-block;
                vertical-align: top;

                &:first-child{
                    margin-right:5px;
                }
            }
        }
    }
</style>
